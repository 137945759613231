import { FC, memo } from 'react';
import Image from 'next/image';
import { VacancyAdCard } from '../../../../../api_entities/vacancies/types';
import {
  Container,
  CardsContainer,
  Card,
  CardText,
  Button,
  CardsWrapper,
  BlockTitle,
  CardTitle,
  JobCard,
  JobCompany,
  RecommendedTag,
  JobPropertyItem,
  VacancyImageWrapper,
  CardBody,
  LogoImageWrapper,
} from './VacanciesAdSection.styled';
import { normalizeSalary } from '../../../../components/Cards/utils';
import { parseImageUrl } from '../../../../../utils/helpers';
import placeholder from '../../../../../public/assets/placeholders/placeholder-19.svg';
import { ImageWithFallback } from '../../../../common';

export type VacanciesAdSectionProps = {
  title: string;
  vacancyAdCards: VacancyAdCard[];
};

const VacanciesAdSection: FC<VacanciesAdSectionProps> = ({ title, vacancyAdCards }) => {
  return (
    <Container>
      <CardsWrapper>
        <BlockTitle>{title}</BlockTitle>
        <CardsContainer>
          {vacancyAdCards.map((vacancy) => {
            const {
              isRecommended,
              imageUrl,
              companyLogo,
              companyName,
              title,
              shortDescription,
              description,
              employmentType,
              city,
              salaryCurrency,
              salaryUnit,
              salaryMin,
              salaryMax,
              vacancyUrl,
              linkText,
              openInNewWindow,
              importantInfo,
            } = vacancy;
            const salaryRate = normalizeSalary({
              salaryCurrency,
              salaryUnit,
              salaryMin,
              salaryMax,
            });

            const vacancyImage = parseImageUrl(imageUrl);
            const logo = parseImageUrl(companyLogo);
            const text = shortDescription || description;

            const highlight = isRecommended || !!importantInfo;
            const linkOptions = openInNewWindow
              ? { target: '_blank', rel: 'noopener noreferrer' }
              : {};

            return (
              <Card key={vacancyUrl} recommended={highlight}>
                {importantInfo && <RecommendedTag>{importantInfo}</RecommendedTag>}
                <VacancyImageWrapper>
                  <ImageWithFallback
                    priority
                    unoptimized
                    fallbackSrc={placeholder}
                    src={vacancyImage.src || placeholder}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                  />
                </VacancyImageWrapper>
                <CardBody>
                  <JobCard>
                    <JobCompany>
                      {logo.src && (
                        <LogoImageWrapper>
                          <ImageWithFallback
                            priority
                            unoptimized
                            fallbackSrc={placeholder}
                            src={logo.src || placeholder}
                            layout="fill"
                            objectFit="contain"
                            objectPosition="center"
                          />
                        </LogoImageWrapper>
                      )}
                      <span>{companyName}</span>
                    </JobCompany>
                    {city.length > 0 ? (
                      <JobPropertyItem imageUrl="/assets/jobLocation.svg">
                        {city.join(', ')}
                      </JobPropertyItem>
                    ) : null}

                    {employmentType && employmentType.length > 0 ? (
                      <JobPropertyItem imageUrl="/assets/clock.svg">
                        {employmentType.join(', ')}
                      </JobPropertyItem>
                    ) : null}

                    {salaryRate && (
                      <JobPropertyItem imageUrl="/assets/money-euro.svg">
                        {salaryRate}
                      </JobPropertyItem>
                    )}
                  </JobCard>
                  <CardTitle>{title}</CardTitle>
                  <CardText dangerouslySetInnerHTML={{ __html: text || '' }} />
                  <Button href={vacancyUrl} recommended={highlight} {...linkOptions}>
                    {linkText}
                    <Image
                      src={highlight ? '/assets/rightWhite.svg' : '/assets/rightOrange.svg'}
                      alt="Arrow"
                      width={24}
                      height={24}
                    />
                  </Button>
                </CardBody>
              </Card>
            );
          })}
        </CardsContainer>
      </CardsWrapper>
    </Container>
  );
};

export default memo(VacanciesAdSection);
