import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;

  color: ${({ theme }) => theme.system.black};
  background-color: ${({ theme }) => theme.brand.lightOrange};
`;

export const TextWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;
`;

export const Text = styled.p`
  font-size: 1.2rem;
  line-height: 1.3;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 24px;
`;

export const BottomDecoration = styled.div`
  width: 100%;
  height: 93px;
  background-image: url('/assets/cta/ready-to-job-cta-2.svg');
  background-repeat: repeat no-repeat;
  background-position: center;
`;

export const Inner = styled.div`
  padding: 16px 16px 40px;

  ${up('md')} {
    padding: 32px 24px 40px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const ImageWrapper = styled.div`
  max-width: fit-content;
  margin: 0 auto 16px;

  ${up('md')} {
    margin-bottom: 32px;
  }
`;

export const StyledLink = styled.a`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.system.white};
  background-color: ${({ theme }) => theme.brand.orange};
  border-radius: 9px;
  padding: 11px 32px;
  border: 3px solid ${({ theme }) => theme.brand.orange};
  transition: 0.2s linear;

  font-weight: 600;
  font-size: 1.6rem;
  line-height: 1.2;

  &:hover {
    border-color: ${({ theme }) => theme.brand.darkOrange};
    background-color: ${({ theme }) => theme.brand.darkOrange};
    color: ${({ theme }) => theme.system.lightGrey};
  }
`;

export const ShareWrapper = styled.div`
  padding: 14px;

  ${Text} {
    text-align: center;
    margin-bottom: 8px;
    color: ${({ theme }) => theme.system.darkGreyActive};
  }
`;

export const SocialBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  button {
    appearance: none;
    border-radius: 100%;
    transition: 0.3s linear;
    padding: 10px;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    position: relative;

    border: 1px solid ${({ theme }) => theme.system.gray};
    color: ${({ theme }) => theme.brand.blue};

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      cursor: pointer;

      border: 2px solid ${({ theme }) => theme.brand.blue};
    }

    span {
      width: 1px;
      height: 1px;
      overflow: hidden;
      clip: rect(0 0 0 0);
      position: absolute;
    }
  }
`;
