import { FC, memo } from 'react';
import { useRouter } from 'next/router';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icons } from '@studenten/ui-components';

import * as types from 'api_entities/strapi/types';

import {
  Container,
  Inner,
  Content,
  Title,
  Text,
  StyledLink,
  SocialBlock,
  TextWrapper,
  ShareWrapper,
  BottomDecoration,
} from './ReadyToJobCtaLite.styles';
import Link from 'next/link';

export type ReadyToJobCtaLite = {
  title: string;
  subtitle?: string;
  link?: Pick<types.LinkComponent, 'label' | 'href'>;
  shareText?: string;
};

const ReadyToJobCtaLite: FC<ReadyToJobCtaLite> = ({ title, subtitle, link, shareText }) => {
  const router = useRouter();
  const shareUrl = `${process.env.NEXT_PUBLIC_HOME_URL}${router.asPath}`;

  return (
    <div>
      <Container>
        <Inner>
          <Content>
            <TextWrapper>
              <Title>{title}</Title>
              <Text>{subtitle}</Text>
            </TextWrapper>
            {link?.href ? (
              <Link passHref href={link?.href}>
                <StyledLink>{link.label}</StyledLink>
              </Link>
            ) : null}
          </Content>
        </Inner>
        <BottomDecoration />
      </Container>
      {shareText ? (
        <ShareWrapper>
          <Text>{shareText}</Text>
          <SocialBlock>
            <TwitterShareButton url={shareUrl} resetButtonStyle={false}>
              <span>Share this page with Twitter.</span>
              <Icons.Twitter />
            </TwitterShareButton>
            <FacebookShareButton url={shareUrl} resetButtonStyle={false}>
              <span>Share this page with Facebook.</span>
              <Icons.Facebook />
            </FacebookShareButton>
            <CopyToClipboard text={shareUrl}>
              <button>
                <span>Copy to clipboard.</span>
                <Icons.ChainLink />
              </button>
            </CopyToClipboard>
          </SocialBlock>
        </ShareWrapper>
      ) : null}
    </div>
  );
};

export default memo(ReadyToJobCtaLite);
