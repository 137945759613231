import { FC, memo } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Icons } from '@studenten/ui-components';
import { LinkComponent } from 'api_entities/strapi/types';

import abstractImage1 from 'public/assets/cta-abstraction-1_1-2.svg';
import abstractImage2 from 'public/assets/cta-abstraction-1_2-2.svg';
import abstractImage3 from 'public/assets/cta-abstraction-2.svg';

import {
  Container,
  Content,
  H2,
  StyledLink,
  IconsRow,
  AbstractionLeft,
  AbstractionRight,
} from './CheckOutJobs.styles';

export type CheckOutJobsProps = {
  title: string;
  link?: Pick<LinkComponent, 'href' | 'label'>;
};

const CheckOutJobs: FC<CheckOutJobsProps> = ({ title, link }) => {
  return (
    <Container>
      <Content>
        <AbstractionLeft>
          <Image
            role="presentation"
            width={100}
            height={100}
            layout="fixed"
            src={abstractImage1}
            alt=""
          />
          <Image
            role="presentation"
            width={100}
            height={100}
            layout="fixed"
            src={abstractImage2}
            alt=""
          />
        </AbstractionLeft>
        <IconsRow>
          <Icons.CookingIcon />
          <Icons.AnalysisIcon />
          <Icons.BusIcon />
          <Icons.BrowserIcon />
          <Icons.HotelIcon />
        </IconsRow>
        <H2>{title}</H2>
        {!!link && (
          <Link passHref href={link.href}>
            <StyledLink>{link.label}</StyledLink>
          </Link>
        )}

        <AbstractionRight>
          <Image
            role="presentation"
            width={100}
            height={200}
            layout="fixed"
            src={abstractImage3}
            alt=""
          />
        </AbstractionRight>
      </Content>
    </Container>
  );
};

export default memo(CheckOutJobs);
