import styled from 'styled-components';

import { device } from 'constants/device';

export const Container = styled.section`
  width: 100%;
  background-color: ${({ theme }) => theme.brand.blue};
`;

export const Content = styled.div`
  position: relative;
  padding: 72px 108px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  min-height: 328px;
  text-align: center;
  @media ${device.laptop} {
    padding: 56px 58px;
  }
  @media ${device.mobileL} {
    padding: 56px 16px;
  }
`;

export const H2 = styled.h2`
  max-width: 610px;
  margin: 0 auto 42px;
  text-align: center;
  font-size: 3.2rem;
  line-height: 4.2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.system.white};

  @media ${device.mobileL} {
    max-width: none;
    font-size: 2rem;
    line-height: 2.4rem;
    font-weight: 600;
  }
`;

export const StyledLink = styled.a`
  min-width: 186px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  color: ${({ theme }) => theme.system.white};
  background-color: ${({ theme }) => theme.brand.orange};
  border-radius: 9px;
  transition: color 0.2s ease-in;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;

  @media ${device.tablet} {
    min-width: 243px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.brand.darkOrange};
  }
`;

export const IconsRow = styled.div`
  margin-bottom: 24px;

  svg {
    width: 40px;
    height: 40px;
    color: ${({ theme }) => theme.system.white};

    @media ${device.tablet} {
      width: 32px;
      height: 32px;
    }
  }

  svg:not(:last-of-type) {
    margin-right: 16px;

    @media ${device.tablet} {
      margin-right: 8px;
    }
  }
`;

export const AbstractionLeft = styled.div`
  max-height: 100px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;

  @media ${device.laptop} {
    display: none;
  }
`;

export const AbstractionRight = styled.div`
  max-height: 200px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(50%);

  @media ${device.laptop} {
    display: none;
  }
`;
