import { down } from 'styled-breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: -82px;

  ${down('md')} {
    margin-top: 16px;
  }
`;

export const CardsWrapper = styled.div`
  width: 100%;
  max-width: 1224px;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const CardsContainer = styled.div`
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  gap: 24px;
  padding: 16px 0;
`;

export const BlockTitle = styled.span`
  color: #fff;

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;

  ${down('md')} {
    color: #000;
    padding: 0 16px;
  }
`;

export const Card = styled.div<{ recommended: boolean }>`
  flex: 0 0 auto;
  position: relative;
  width: 272px;
  min-height: 470px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid ${(props) => (props.recommended ? '#e07400' : '#EEEEF1')};
  background: #fff;
`;

export const RecommendedTag = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  background: #e07400;
  padding: 4px 0;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
`;

export const VacancyImageWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  flex: 0 0 200px;
`;

export const LogoImageWrapper = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: block;
  position: relative;
  min-width: 40px;
  width: auto;
  height: 40px;
  flex-shrink: 0;
`;

export const CardBody = styled.div`
  padding: 12px 10px 16px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const JobCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 1;
  width: 100%;
  padding: 8px;
  height: 140px;
  background: #fff;
  margin: -35% auto 16px;
  border-radius: 4px;
  box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.1);
`;

export const JobCompany = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  color: #3c3e45;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;

  span {
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
  }
`;

export const JobPropertyItem = styled.div<{ imageUrl: string }>`
  position: relative;
  padding-left: 24px;
  color: #3c3e45;
  font-size: 12px;
  line-height: 1.3;
  font-weight: 500;

  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;

  &:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    background-image: ${({ imageUrl }) => `url(${imageUrl})`};
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const CardTitle = styled.span`
  margin-bottom: 12px;
  color: #3c3e45;
  font-size: 14px;
  font-weight: 600;
  text-overflow: ellipsis;

  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
`;

export const CardText = styled.span`
  width: 100%;
  color: #3c3e45;
  font-size: 12px;
  line-height: 1.15;

  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  display: -webkit-box;
`;

export const Button = styled.a<{ recommended: boolean }>`
  margin-top: auto;
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: ${(props) => (props.recommended ? '#fff' : '#ef7d00')};
  display: flex;
  padding: 9px 32px;
  border: 1px solid #ef7d00;
  background-color: ${(props) => (props.recommended ? '#ef7d00' : '#fff')};
  border-radius: 8px;
  transition: border-color 0.1s ease-in, color 0.1s ease-in;
  justify-content: center;

  img {
    vertical-align: middle;
  }

  &:hover {
    border-color: ${(props) => (props.recommended ? '#e07400' : '#ef7d00')};
    background-color: ${(props) => (props.recommended ? '#e07400' : '#fff')};
    color: ${(props) => (props.recommended ? '#fff' : '#e07400')};
  }
`;
